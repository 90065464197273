.containerAll{
    height: 100vh;
    width: 100%;
  
}
.divJudul{
    height: 20%;
    width: 100%;
    border-bottom: solid 2px rgb(230, 230, 230);
}
.divTulisanJudul{
    display: flex;
    align-items: center;
    padding-left: 2%;
    box-sizing: border-box;
    font-size: 3vw;
height: 50%;
width: 100%;
font-family:Georgia;
color: #2f69fd;
position: relative;
}

.divLinkJudul{
    display: flex;
    height: 50%;
    width: 100%;
}
.divLink{
    height: 100%;
    flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   
}
.akun {
    position: absolute;
    right: 2%;
    height: 4vw;
    width: 4vw;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.akun img {
    border-radius: 2.5vw;
    background-color: rgb(230, 230, 230);
    height: 100%;
    width: 100%;
}
.notVisibleDivGantiAkun {
    display: none;
}

.visibleDivGantiAkun {
    border-radius: 0.5vw;

    position: absolute;
    right: 0.5%;
    top: 88%;
    height: 100%;
    width:15%;
    background-color: rgb(160, 160, 160);
   
}
.judulGantiAkun{
    display: flex;
    height: 25%;
    justify-content: center;
    align-items: center;
    color: white;
    border-bottom: solid 1px white;
    font-size: 1vw;
}
.pilihanGantiAkun{
    height: 75%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.pilihanGantiAkun button{
cursor: pointer;
    border-radius: 0.5vw ;
    height: 50%;
    width: 30%;
}