.containerAll{
    height: 100vh;
    width: 100%;
   position: relative;
}
.inputBayangan{
    background-color: transparent;
    color: transparent;
    outline: none;
    caret-color: transparent;
   
    
    border: none;
    position: absolute;
    top: 0;
    right: 0;
}
.tulisanAttendance{
    height: 20%;
    width: 100%;
 border-bottom: solid 2px rgb(230, 230, 230);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vw;
    font-family:Georgia;
    position: relative;
}
.tulisanAttendance img{
    position: absolute;
    left: 1%;
    height: 7vw;
    width: 7vw;
    cursor: pointer;
}
.divKotakTap{
    height: 30%;
    width: 50%;
    border-radius: 2vw;
   position: absolute;
   top: 35%;
   left: 25%;
  
}
.divKotakTap input{
    font-size: 3vw;
    height: 100%;
    width: 100%;
    border-radius: 2vw;
text-align: center;
caret-color: transparent; /* Menyembunyikan kursor */
outline: none; /* Opsional: Menghapus outline pada input saat fokus */
}
.divSudahScan{
        height: 40%;
        width: 100%;
     
       position: absolute;
       top: 35%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.sudahScanAtas{
    font-family:Georgia;
    color:#2f69fd;
    font-size: 2vw;
    margin-bottom: 2%;
}
.sudahScanBawah{
    font-family:Helvetica;
    font-size: 2vw;
}
