.containerAll{
    height: 78%;
    width: 100%;
   display: flex;
  align-items: center;
   flex-direction: column;
 position: relative;
}
.judul{
    display: flex;
    margin-top: 2%;
    height: 10%;
    width: 50%;
    
    position: relative;
    justify-content: center;
    align-items: center;
}
.tulisanJudul{
font-size: 2vw;
}
.tulisanNew{
    color: white;
    height: 3vw;
    width:3vw;
    position: absolute;
    right: 3%;
    background-color: rgb(196, 178, 178);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2vw;
    cursor: pointer;
}
  .divTable{
    height:80% ;
    width: 40%;
   
    overflow-y: auto;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    
  }
  .trSticky{
    position: sticky;
    top: 0;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2; 
  }
  .notVisibleDivInput{
    display: none;
  }
  
  .visibleDivInput{
    position: absolute;
    right: 1%;
    margin-top: 7%;
    height: 60%;
    width: 25%;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 1vw;
    border: solid 1px rgb(224, 224, 224)
    
  }
  .visibleDivInput input{
    border-radius: 1vw;
    margin-top: 3%;
    height: 10%;
    width: 70%;
  }

  .visibleDivInput select{
    border-radius: 1vw;
    margin-top: 3%;
    height: 10%;
    width: 70%;
  }
  .visibleDivInput button{
    border-radius: 1vw;
    margin-top: 3%;
    height: 12%;
    width: 40%;
    cursor: pointer;
  }