/*body {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
  }
  
  .menu-table, .receipt {
    width: 45%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }*/

  .containerAll{
   
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .divMenuTable{
    height: 100%;
    width: 45%;
   
  }
  .divReceipt{
    height: 100%;
    width: 45%;
  }

  