.containerAll{
    height: 100%;
    width: 100%;
}
.divGambar{
    height: 80%;
    width: 80%;
    margin-left: 10%;
    cursor: pointer;
    
}
.divGambar img{
    height: 100%;
    width: 100%;
}
.divNama{
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}