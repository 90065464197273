.containerAll{
    height: 78%;
    width: 100%;
    display: flex;
}
.containerKiri{
    height: 100%;
    width: 25%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: solid 1px #ddd ;
}
.divAfiliatorGym{
    height: 45%;
    width: 80%;
    margin-top: 2%;
}
.judulAfiliatorGym{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:Georgia;

}
.isiAfiliatorGym{
  height: 90%;
    width: 100%;

    overflow-y: auto;
  }
  .tableAfiliator {
    width: 100%;
    border-collapse: collapse;
    
  }

  
  .tableAfiliator th, .tableAfiliator td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .tableAfiliator th {
    background-color: #f2f2f2; 
  }
  .containerKanan{
    height: 100%;
    width: 75%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divDetailZumba{
    height: 90%;
    width: 80%;

  }
  .judulDetail{
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:Georgia;

  }
  .isiDetail{
    height: 85%;
    width: 100%;
    overflow-y: auto;
  
  }
  .trSticky{
    position: sticky;
    top: 0;
  }
  .thNamaBarang{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoPanah{
    position: absolute;
    right: 0;
    height: 25%;
    margin-right: 1%;
    cursor: pointer;
  }
  .notVisibleContainerFilter{
    display: none;
  }
  .visibleContainerFilter{
    display: flex;
 justify-content: center;
 align-items: center;
    background-color: rgb(224, 224, 224);
    top:100%;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
  }
  .divContentFilter{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 60%;
    
  }
  .inputFilter{
    width: 60%;
  }
  .imgCentang{
  cursor: pointer;
    width: 10%;
    height: 90%;
  }
  .imgSilang{
    cursor: pointer;
    width: 8%;
    height: 70%;
  }
  /* div atas */
.divAtas{
  height: 4%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 2%;
  }
  
  
  
  .divAtas input{
      height: 100%;
      width:13% ;
   
      
  }
  .divLogoPanah{
      height: 100%;
      width: 4%;
      margin-left: 1%;
      margin-right: 1%;
  }
  .divAtas div img{
      height: 100%;
      width: 100%;
  }

  

  
