.containerAll{
    height: 100vh;
    width: 100%;
  
}
.divJudul{
    height: 20%;
    width: 100%;
    border-bottom: solid 2px rgb(230, 230, 230);
}
.divTulisanJudul{
    display: flex;
    align-items: center;
    padding-left: 2%;
    box-sizing: border-box;
    font-size: 3vw;
height: 50%;
width: 100%;
font-family:Georgia;
color: #2f69fd;
position: relative;
}
.divTulisanJudul img{
    height: 5vw;
    width: 5vw;
    position: absolute;
    right: 1%;
    top: 10%;
    cursor: pointer;
}

.divLinkJudul{
    display: flex;
    height: 50%;
    width: 100%;
}
.divLink{
    height: 100%;
    flex: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
