.containerAll{
    height: 100%;
    width: 100%;
 
    overflow-y: auto;
}
.divTable{
    height: 10%;
    width: 100%;
  
    
}
.table {
   
    width: 100%;
    border-collapse: collapse;
   
  }
  
.trSticky{
    position: sticky;
    top: 0;
}
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  .tdQuantity{
    display: flex;
    justify-content: space-between;
  
  }