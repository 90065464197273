.containerAll {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .divTable {
    overflow-x: auto;
  }
  
  .tableContainer {
    max-height: 70vh; /* 70% of viewport height */
    overflow-y: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .trSticky {
    position: sticky;
    top: 0;
    background-color: #f9f9f9;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .totalSection {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  