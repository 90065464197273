.containerAll {
    height: 100vh;
    width: 100%;
}

.divJudul {
    position: relative;
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 3px rgb(230, 230, 230);
}

.judul {
    font-family: Georgia;
    font-size: 5vw;
}

.akun {
    position: absolute;
    right: 3%;
    height: 5vw;
    width: 5vw;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.akun img {
    border-radius: 2.5vw;
    background-color: rgb(230, 230, 230);
    height: 100%;
    width: 100%;
}

.divKeteranganAkun {
    display: none;
    height: 15%;
    width: 100%;
    background-color: rgb(105, 105, 105);
    position: absolute;
    top: 100%;
    right: 0;
    padding: 5px;
    color: white;
    border-radius: 3px;
    white-space: nowrap;
}

.akun:hover .divKeteranganAkun {
    display: flex;
    justify-content: center;
    align-items: center;
}

.notVisibleDivGantiAkun {
    display: none;
}

.visibleDivGantiAkun {
    border-radius: 0.5vw;

    position: absolute;
    right: 0.5%;
    top: 85%;
    height: 100%;
    width:15%;
    background-color: rgb(160, 160, 160);
   
}
.judulGantiAkun{
    display: flex;
    height: 25%;
    justify-content: center;
    align-items: center;
    color: white;
    border-bottom: solid 1px white;
}
.pilihanGantiAkun{
    height: 75%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.pilihanGantiAkun button{
cursor: pointer;
    border-radius: 0.5vw ;
    height: 50%;
    width: 30%;
}

.isi {
    height: 84%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divButtonKotak {
    height: 40%;
    width: 18%;
    border: solid 1px rgb(230, 230, 230);
    margin-right: 3%;
    border-radius: 2vw;
    box-shadow: -10px 10px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
